#home {

.hero .content img {
    max-height: 32px;
    height: auto;
    min-height: 1px;
    min-width: 1px;
    width: auto;
    max-width: 100%;
}

h3 {
  text-transform: uppercase;
  letter-spacing: 1;
  font-weight: weight('semi-bold');
}

.solucion {
  padding: 3rem;
  @include media-breakpoint-down(md) {
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
  img.logo {
    width: auto;
    height: 32px;
    display: block;
    margin: 0 auto 1.5rem auto;
  }
  img:not(.logo) {
    border-width: 1rem;
    border-style: solid;
    margin: 0 auto 1.5rem auto;
  }
  .title {
    text-transform: inherit;
  }
  .card-body {
    text-align: center;
  }
}

.card.custom.testimonial {
  text-align: center;
  position: relative;
  @include media-breakpoint-down(sm) { margin-bottom: 3rem; }
  .title {
    padding: 2rem;
    font-weight: weight('medium');
    div:nth-child(1) {
      color: $primary;
      font-size: heading('heading-3');
      line-height: 1;
      margin-bottom: .5rem;
    }
    div:nth-child(2) {
      font-size: 90%;
      text-transform: uppercase;
      font-weight: weight('medium');
    }
  }
  a.btn {
    position: absolute;
    bottom: 0;
    margin: 0 0 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    align-self: center;
  }
  img {
      height: 100%;
  }
}

.card.custom .card-body.testimonial-old-bk {
  text-align: center;
  &.title {
    padding: 1rem 1rem;
    background-color: $primary;
    color: white;
    font-weight: weight('medium');
    div:nth-child(1) {
      font-size: heading('heading-4');
      line-height: 1;
      margin-bottom: .5rem;
    }
    div:nth-child(2) {
      @extend .small;
      text-transform: uppercase;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    li a {
      font-size: 110%;
      font-weight: weight('medium');
    }
  }
}

.card.custom .card-body.blog {
  padding: $card-custom__blog__padding-y $card-custom__blog__padding-x;
  .date {
    font-style: italic;
    font-size: 90%;
    opacity: .7;
    margin-bottom: .5rem;
  }
  a.title {
    margin-bottom: 1rem;
    line-height: 1.1;
    color: $primary;
    font-size: 140%;
    font-weight: weight('semi-bold');
    text-decoration: none;
    display: block;
  }
  p { font-size: 90%; }
  a.read-more {
    letter-spacing: 1;
    font-weight: weight('semi-bold');
  }
}

  #news-blog {
      h2 img {
          height: 60px;
          width: auto;
      }
      .card .card-img-top {
          height: 230px;

          img {
              height: 100%;
              object-fit: cover;
          }
      }
  }

  .calculadora-ahorro {
    tbody td {
      padding: .25rem 1rem;
      &:first-child {
        border-right: 1px solid #f59000;
      }
    }
    tbody:not(.v-adm) td {
        &:last-child,
        &:last-child::selection {
          color: transparent !important;
          text-shadow: 0 0 10px rgba(0,0,0,1);
        }
    }
    thead th:first-child {
      border-right: 1px solid #f59000;
    }
    tbody tr:not(:last-of-type) {
      border-bottom: 1px solid #f59000;
    }
  }

}
