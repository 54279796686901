$navbar__padding-x: 1rem;
$navbar__pading-y: .5rem;

$footer__mini__padding-x: 1rem;
$footer__mini__padding-y: .5rem;

$nav-hero__padding-top: 2rem;
$hero__padding-y: 7rem;
$hero-small__padding-y: 4rem;
$hero-small__padding-x: 5rem;

$card-component__border: 15px;

$card-custom__blog__padding-x: 2rem;
$card-custom__blog__padding-y: 2rem;

$cta__padding-y: 4rem;
$cta__padding-x: 0;

$underline__height: 4px;
