// FONTS
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Poppins:300,400,500,600,700&display=swap');

/*
 * Custom fonts
 * Functions declared in _mixins.scss
 */
$fonts: (
  regular: sans-serif,
  Font Awesome: unquote("'Font Awesome', sans-serif"),
  Poppins: unquote("'Poppins', sans-serif"),
  Open Sans: unquote("'Open Sans', sans-serif")
);

/*
 * Custom font weights
 * Functions declared in _mixins.scss
 */
$weights: (
  thin: 100,
  extra-light: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  extra-bold: 800
);

/*
 * Custom heading sizes
 * Functions declared in _mixins.scss
 */
$heading-sizes: (
  heading-1: 2.5rem,
  heading-2: 2.25rem,
  heading-3: 2rem,
  heading-4: 1.5rem,
  heading-5: 1.20rem,
  heading-6: 1.10rem
);

// COLORS
$primary: #F59100;
$secondary: #4f5d5b;
$secondary-dark: darken(#4f5d5b,5%);
$secondary-light: #c3c3c3;
$primary-light: #fef8ef;

// SOLUCIONES
$gourmet-ticket: $primary;
$transport-ticket: #59AE23;
$activa: #0B72B3;
$educainfantil: #01A8EC;
$cadhoc: #BD0C1B;
$retriplus: #F59100;
$grass-roots: #73b6bb;

// RRSS
$twitter: #00aced;
$whatsapp: #29a628;
$messenger: #0084ff;
$facebook: #3b5998;
$linkedin: #0077B5;
$instagram: #C13584;
$youtube: #CC181E;

$colors: (
  primary: $primary,
  primary-light: $primary-light,
  secondary: $secondary,
  secondary-dark: $secondary-dark,
  secondary-light: $secondary-light,
  gourmet-ticket: $gourmet-ticket,
  transport-ticket: $transport-ticket,
  activa: $activa,
  educainfantil: $educainfantil,
  cadhoc: $cadhoc,
  retriplus: $retriplus,
  grass-roots: $grass-roots,
  twitter: $twitter,
  whatsapp: $whatsapp,
  messenger: $messenger,
  facebook: $facebook,
  linkedin: $linkedin,
  instagram: $instagram,
  youtube: $youtube
);

$theme-colors: (
  primary: $primary,
  primary-light: $primary-light,
  secondary: $secondary,
  secondary-dark: $secondary-dark,
  secondary-light: $secondary-light,
  gourmet-ticket: $gourmet-ticket,
  transport-ticket: $transport-ticket,
  activa: $activa,
  educainfantil: $educainfantil,
  cadhoc: $cadhoc,
  retriplus: $retriplus,
  grass-roots: $grass-roots,
  twitter: $twitter,
  whatsapp: $whatsapp,
  messenger: $messenger,
  facebook: $facebook,
  linkedin: $linkedin,
  instagram: $instagram,
  youtube: $youtube
);

// OVERRIDES
.btn {
  cursor: pointer !important;
  transition: color .15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out !important;
  border-radius: 0 !important;
  text-transform: uppercase;
  font-weight: map-get($weights, 'medium') !important;
}
.btn-primary,
.btn-educainfantil,
.btn-retriplus,
.btn-grass-roots {
  color: white !important;
}


.btn-primary.form-control:focus {
  background-color: $primary !important;
  border-color: $primary !important;
}
.btn-educainfantil, .btn-educainfantil.form-control:focus {
  background-color: $educainfantil !important;
  border-color: $educainfantil !important;
}
.btn-transport-ticket, .btn-transport-ticket.form-control:focus {
  background-color: $transport-ticket !important;
  border-color: $transport-ticket !important;
}
.btn-retriplus, .btn-retriplus.form-control:focus {
  background-color: $retriplus !important;
  border-color: $retriplus !important;
}
.btn-cadhoc, .btn-cadhoc.form-control:focus {
  background-color: $cadhoc !important;
  border-color: $cadhoc !important;
}

.svg.text-educainfantil { fill: $educainfantil; }
.svg.text-primary { fill: $primary; }
.svg.text-transport-ticket { fill: $transport-ticket; }
.svg.text-retriplus { fill: $retriplus; }
.svg.text-cadhoc { fill: $cadhoc; }

.btn-outline-primary {
  &:hover {
    color: $primary !important;
  }
}
.btn.icon-animation {
  position: relative;
  svg {
    position: absolute;
    top: -2px;
    bottom: 0;
    margin: auto 0;
    opacity: 0;
    transform: translateX(-40px);
    color: white;
    transition: .3s opacity ease, .2s transform ease-in-out;
  }
  &:hover svg {
    opacity: 1;
    transform: translateX(-25px);
  }
  &.btn-sm svg { transform: translateX(-20px); }
  &.btn-sm:hover svg { transform: translateX(-15px); }
}

.btn-sm, .btn-group-sm > .btn {
  font-size: inherit;
  padding: .5rem 1rem !important;
  font-size: .7rem !important;
}
a.btn {
  text-decoration: none !important;
}
.text-linkedin {
    color: $linkedin !important;
}
.btn-linkedin {
    background-color: $linkedin;
    color: #fff;
}
