#club {

.hero {
  padding-bottom: $hero-small__padding-y;
  > .container > .content {
    margin-top: $hero-small__padding-y;
    @include media-breakpoint-up(md) {
      padding-left: $hero-small__padding-x;
    }
    @include media-breakpoint-down(sm) {
      .row .col:not(:last-of-type) { margin-bottom: 2rem; }
      .row .col > .btn { white-space: nowrap; }
    }
  }
}

h3 {
  letter-spacing: 1;
  font-weight: weight('semi-bold');
}
.partners figure.item {
  margin: 0;
  @include media-breakpoint-down(sm) { margin-bottom: 2rem; }
}

.partners figure.item .media-header {
  position: relative;
  &:before {
    @extend .overlay;
    background-color: rgba(0,0,0, .2);
    z-index: 1;
    @extend .transition;
  }
}
.partners figure.item:hover .media-header:before {
    background-color: transparentize($primary, .4);
}
.partners figure.item .media-header img:not(.icon) {
  opacity: .7;
  filter: contrast(60%);
}
.partners figure.item .media-header img.icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 10;
  max-height: 80%;
  height: 80%;
}
.partners figure.item a {
  text-decoration: none;
  color: inherit;
  figcaption p:last-of-type { margin-bottom: 0; }
}

}


.section-club .sidenav {
  margin-top: 3rem;
  margin-bottom: 1rem;
}


@media (min-width: 992px) {

  .section-club figure {
    width: 28.35314%;
    margin-right: 7.47%;
  }
  .section-club .sidenav {
      position: sticky;
      z-index: 1;
      top: 0;
      left: 0;
      overflow-x: hidden;
      margin: 0;
    }

    .section-club .sidenav b {
      color: #818181;
      text-decoration: none;
    }

    
    .section-club .sidenav a, .section-club .sidenav p {
      font-size: 20px;
      display: block;
      text-decoration: none;

    }
    .section-club .sidenav span {
      text-decoration: underline;
    }

}