#blog {
  .modal#cta-modal-leave .modal-content {
    border: none;
    background: none!important
  }

img[src='https://static.hsstatic.net/BlogImporterAssetsUI/ex/missing-image.png'] {
    display: none;
}


.card {
    .card-img-top {
        height: 230px;

        img {
            height: 100%;
            object-fit: cover;
            max-width: 100%;
        }
    }
    .card-body {
        iframe {
            width: 100%;
            height: auto;
        }
    }
}
article {
  padding: 3rem 0 0;
  #body img {
    height:auto;
    max-width: 100%;
  }
}

.article {
  ul.info {
    font-size: .8rem;
    opacity: 80%;
    list-style: none;
    padding: 0;
    margin-bottom: 1.5rem;
  }
  ul.info li {
    display: inline-block;
    &:not(:last-child):after {
      content: '·';
      margin: 0 .5rem;
    }
  }
}

.article ul.social-share {
  list-style: none;
  padding: 0;
  display: inline-block;
  li {
    display: inline-block;
    background-color: #eee;
    padding: 0.25rem 1rem;
    &:hover { @extend .transition; }
    &:not(:last-child) { margin-right: .5rem; }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  li[class*='facebook']:hover   { color: white; background-color: $facebook; }
  li[class*='linkedin']:hover   { color: white; background-color: $linkedin; }
  li[class*='messenger']:hover  { color: white; background-color: $messenger; }
  li[class*='twitter']:hover    { color: white; background-color: $twitter; }
  li[class*='whatsapp']:hover   { color: white; background-color: $whatsapp; }
}

.sidebar ul.social-share {
  list-style: none;
  padding: 0;
  display: inline-block;
  li {
    display: inline-block;
    &:not(:last-child) { margin-right: .5rem; }
    a {
      font-size: heading('heading-3');
      color: inherit;
    }
    a svg { @extend .transition; }
  }
  li:hover a svg { @extend .transition; }
  li:hover a svg[class*='facebook']:not([class*='messenger']) { color: $facebook; }
  li:hover a svg[class*='linkedin']                           { color: $linkedin; }
  li:hover a svg[class*='messenger']                          { color: $messenger; }
  li:hover a svg[class*='twitter']                            { color: $twitter; }
  li:hover a svg[class*='whatsapp']                           { color: $whatsapp; }
}

.sidebar ul.realated-articles {
  li.media {
    margin-bottom: .5rem;
  }
  li.media a {
    display: flex;
    align-items: self-start;
    text-decoration: none;
    color: inherit;
  }
  li.media img { max-width: 25%; }
  li.media .media-body h5 { font-size: 1rem; }
  li.media .media-body {
    font-size: 80%;
  }
}


  .modal#cta-modal-leave {
    //transform: translateY(20%);
    .modal-header {
      border:none;
      h5 {
        display:none;
        .close {
          color:#FFF;
          text-shadow:none;
        }

      }
    }
    .modal-dialog {
      .modal-content {
        background-color:transparent;
      }
    }
    
    
  }
  #cta-modal-leave {
    .modal-dialog {
      max-width: 900px;
      margin: auto;
    }
    img {
      border-width: 0px;
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

}
