#checkout {
  nav.dark {
    padding: 0 !important;
  }
  nav .links {
    font-size: 1.5rem;
  }

  .process .header {
    margin-bottom: 1rem;
    .row > div[class*='col-md'] {
      text-align: center;
      letter-spacing: 1px;
      font-size: 1rem;
      font-weight: weight('medium');
      padding: 2rem 1rem;
      &.actual:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 0;
          height: 0;
          margin: auto;
          border-style: solid;
          border-width: 0 15px 13px 15px;
          border-color: transparent transparent #ffffff transparent;
      }
      &:not(.active) {
        opacity: 0.5;
      }
      &:not(:last-of-type) {
        margin-right: .5rem;
      }
      svg, img, path {
        color: inherit;
        fill: currentColor;
        max-height: 72px;
        width: auto;
        margin-bottom: .5rem;
      }
    }
  }

  .card.custom { display:flex; }
  .card.custom .header {
    padding: 1rem 2rem;
    text-transform: uppercase;
  }
  .cart .card.custom .header {
    border-bottom: 4px solid $primary;
    font-weight: weight('bold');
    text-align: center;
    font-size: 1rem;
  }
  .card.custom [class*='header bg-'] { color: white; }
  .card.custom .body {
    padding: 2rem 2rem;
    font-size: .9rem;
    svg, .svg, path {
      color: inherit;
      fill: currentColor;
      max-width: 24px;
      height: 24px;
    }
    .nav-tabs {
      border-bottom: 1px solid $gray-400;
      border-top: 1px solid $gray-400;
      > li:first-child {
        border-right: 1px solid $gray-400;
      }
      > li {
        cursor: pointer;
        background-color: $gray-100;
        a {
          padding: .8rem 1.5rem;
          color: initial;
          text-decoration: none;
          display: block;
        }
        a.active {
          background-color: $secondary-dark;
          color: white;
        }
      }
    }
  }
  .card.custom .footer {
    margin-top: auto;
    border-top: 1px solid lighten($primary, 10%);
    padding: 1.5rem 2rem;
    display: flex;
  }

  .process .header ul.steps {
    display: inline-block;
    font-size: 0.9rem;
    margin: auto;
    @extend .list-unstyled;
    li {
      display: inline-block;
      color: $secondary;
      margin-left: -2px;
      letter-spacing: 0;
      font-weight: weight('semi-bold');
    }
    li.active {
      color: white;
    }
  }
  .process .form-group {
    align-items: center;
    label {
      margin-bottom: 0;
      &:not(.checkbox) {
        font-style: italic;
        font-weight: weight('light');
      }
    }
  }

  .cart article {
    &:not(:first-child) { border-top: 1px solid lighten($primary, 10%); }
    padding: 1.5rem 2rem;
    .row:not(:last-child) { margin-bottom: .5rem; }
    .title {
      font-weight: weight('medium');
      font-size: 1.1rem;
      margin-bottom: .5rem;
      color: $primary;
    }
    .price {
      margin-left: auto;
      text-align: right;
      font-weight: weight('medium');
    }
    /* DESCOMENTAR SI SE QUIERE FLEX-FLOW
    &.result {
      border-top: 3px solid lighten($primary, 8%);
      margin-top: auto;
    }
    */
  }

}
