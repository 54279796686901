#solution {

.hero {
  padding-bottom: $hero-small__padding-y;
  > .container > .content {
    margin-top: $hero-small__padding-y;
    @include media-breakpoint-up(md) {
      padding-left: $hero-small__padding-x;
    }
    @include media-breakpoint-down(sm) {
      .row .col-md:not(:last-of-type) { margin-bottom: 2rem; }
      .row .col > .btn { white-space: nowrap; }
    }

    h1 {
      font-size: 2.5rem;
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
        word-break: break-all;
      }
      font-weight: weight('semi-bold');
      text-transform: inherit;
      margin: 0;
      line-height: 1.1;
      word-spacing: 100vw; // Forzar a que bajen todas las palabras a la siguiente línea
    }
    a {
      font-weight: weight('semi-bold');
      text-transform: inherit;
    }
  }
}

.ventajas {
  @include media-breakpoint-down(sm) {
    .flex-nowrap { flex-wrap: wrap !important; }
  }
  .item {
    text-align: center;
    @include media-breakpoint-down(sm) {
      &:not(:nth-last-of-type(-n+2)) { margin-bottom: 1.5rem; }
    }
  }
  .item svg, .item img, .item path {
    color: inherit;
    fill: currentColor;
    max-height: 64px;
    width: auto;
    margin-bottom: 1rem;
  }
  h5,
  p {
    font-size: 90%;
  }
  p {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
  }
}

.advantages-accordion {
    list-style: none;
    padding-left: 3rem;

    @include media-breakpoint-down(sm) {
        padding-left: 0;
    }
    .card {
        border: none;
        background: transparent;

        .card-header {
            position: relative;
            border: none;
            background: transparent;
            padding-left: 0;

            .icon-advantage {
                position: absolute;
                top: 0.5rem;
                left: -2.5rem;
                svg {
                    fill: currentColor;
                    height: 40px;
                    width: 40px;
                }
                @include media-breakpoint-down(sm) {
                    display: inline-flex;
                    position: static;
                }
            }

            h3 {
                @include media-breakpoint-down(sm) {
                    display: inline-flex;
                }
                button {
                    text-align: left;
                    white-space: normal;
                }
            }

            @include media-breakpoint-down(sm) {
                padding-right: 0;
                display: inline-flex;
            }
        }
        .card-body {
            padding-left: 1.5rem;
            padding-top: .5rem;

            .subtitle {
                position: relative;
            }
            .subtitle:before {
                content: '';
                top: 9px;
                left: 0px;
                width: 0;
                height: 0;
                position: absolute;
                border-radius: 100%;
                border: 3px solid $primary;
            }
            @include media-breakpoint-down(sm) {
                padding-left: 1.25rem;
            }
        }
    }
}

h3 {
  letter-spacing: 1px;
}

#afiliacion-guarderia.cta {
  background-image: url('../img/pages/educainfantil/cta-1.jpg');
}


.offset-right {
  text-align: right;
  right: -5%;
  position: absolute;
}
form { margin-bottom: 0; }
form .borders {
  padding: 1rem 0;
  border-top: calc(#{$card-component__border}/2.5) solid $primary;
  border-bottom: calc(#{$card-component__border}/2.5) solid $primary;
}
form .total {
  font-size: 110%;
  letter-spacing: 1.2;
  font-weight: weight('semi-bold');
  text-transform: uppercase;
}

#faqs {
  .dot-separator {
    margin: auto 1rem;
    border-radius: 100%;
    background-color: $text-muted;
    height: 5px;
    width: 5px;
  }
  h3 {
    margin-bottom: 0;
    position: relative;
  }
  h3:after { content: ''; @extend .transition; }
  h3.active {
      &.underline-educainfantil:after {
          background-color: $educainfantil;
      }
      &.underline-gourmet-ticket:after {
        background-color: $gourmet-ticket;
    }
      &.underline-transport-ticket:after {
          background-color: $transport-ticket;
      }
      &:after {
          @extend .under;
      }
  }
  h3:hover {
      &.underline-educainfantil:after {
          background-color: $educainfantil;
      }
      &.underline-gourmet-ticket:after {
        background-color: $gourmet-ticket;
    }
      &.underline-transport-ticket:after {
          background-color: $transport-ticket;
      }
      &:after {
          @extend .under;
      }
  }
  .under {
    content: '';
    position: absolute;
    bottom: -12px;
    height: $underline__height;
    background-color: $primary;
    width: 10vw;
    left: 0;
    right: 0;
    margin: auto;
    @include media-breakpoint-down(md) {
      width: 30vw;
    }
  }
}

#compra {
  svg {
    width: 36px !important;
    height: 36px !important;
  }

  input[type="checkbox"] {
    margin-right: 1rem;
  }

  input[type="submit"] {
    padding: 0.8rem 1rem !important;
    font-style: normal !important;
  }
}

}
.fixed {
  position: fixed;
  top: 20px;
  z-index: 200;

  @include media-breakpoint-down(md) {
    z-index: 200;
  }
}

#map-wrapper {
  @include media-breakpoint-down(md) {
    top: 0;
    left: 0;
    width: 100%;

    #map {
      width: 100%;
      height: 250px;
    }
  }
}

.sm-spacer {
  @include media-breakpoint-down(md) {
    padding-top: 10em;
  }
}
.no-decoration {
  text-decoration: none;
  color: #4F5D5B;
}
