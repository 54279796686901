.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  color: $secondary;
  font-family: font('Poppins');
}

img {
  width: 100%;
}

a {
  color: $primary;
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none !important;
}

.flex-grow { flex: 1;}


.btn {
  @include media-breakpoint-down(sm) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
}

.font-awesome {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  display: none;
}
.font-awesome-solid { @extend .font-awesome; font-family: "Font Awesome 5 Solid"; }

.lead {
  line-height: 1.2;
}

.transition {
  transition: .2s all ease-in-out;
}

#cookie-bar {
    display: none;
}

.overlay {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.card-block {
  position: relative;
  border: $card-component__border solid $primary;
}

.cursor {
  cursor: pointer;
}

/* CTAs */
.cta {
  background-position: center right;
  &.cta-mini { padding: calc(#{$cta__padding-y} / 1.5) $cta__padding-x; }
  .btn-dwn {
    display: inline-block;
    img {
      max-height: 46px;
      width: auto;
    }
  }
}
.cta-top {
    border-style: solid;
    border-width: 0px 5px 5px 5px;
    border-radius: 0 0 34px 34px;

    p {
        font-size: .9rem;
    }

}
.cta.searcher-background.visible {
  background-image: url('../img/cta/cta-buscador.jpg');
}


/* FORM */
.form-control {
  font-style: italic;
  border-color: lighten($primary, 15%);
  border-radius: 0.1rem;
  padding: 0.6rem 0.75rem;
  font-size: 0.9rem;
}
.form-control.btn {
    font-style: normal;
}
.form-control.btn-primary,
.form-control.btn-primary:focus {
    border-color: $primary;
    background-color: $primary;
}

@include media-breakpoint-up(md) {
  .w-md {
    &-75 { width: 75% !important; }
    &-50 { width: 50% !important; }
    &-25 { width: 25% !important; }
  }
}

@include media-breakpoint-up(lg) {
  .w-lg {
    &-75 { width: 75% !important; }
    &-50 { width: 50% !important; }
    &-25 { width: 25% !important; }
  }
}

.mini-nav nav.navbar {
  top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 80% !important;
  ul {
    flex-direction: row;
    li { margin-left: auto; text-align: right; }
    li:not(:last-child) { margin-right: .5rem; }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.nav-icon {
  height: 20px;
  width: auto;
  margin-bottom: 2px;
}

body:not(#afiliacion) {
  #alcmeon-webchat-toggle-button {
    @include media-breakpoint-down(md) {
      visibility: hidden !important;
      display: none !important;
    }
  }
  .nav-icon {
    @include media-breakpoint-down(md) {
      visibility: hidden !important;
      display: none !important;
    }
  }
}
@media(max-height: 600px) {
    #alcmeon-webchat-toggle-button {
        width: 50px !important;
        height: 50px !important;
        left: 30px !important;
        bottom: 30px !important;
    }
    .alcmeon-social-net-button {
        width: 40px !important;
        height: 40px !important;
        left: 35px !important;
        bottom: 90px !important;
    }
    #alcmeon-webchat-chat-iframe {
        width: 300px !important;
        height: 450px !important;
        left: 80px !important;
        bottom: 20px !important;
    }
}

.modal-md {
    max-width: 600px;
}

.grecaptcha-badge {
    display: none !important;
}

@include media-breakpoint-up(lg) {
  .w-lg-370 {
    width: 370px;
  }
}