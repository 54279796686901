/* Fixes for IE11
   ========================================================================== */

/* Group */
.ie11 .d-flex.flex-column {
    display: block !important;
}
.ie11 .h-100-ms {
    height: 100% !important;
}
