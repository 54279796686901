#basic {

.valores {
  padding: 4rem;
  @include media-breakpoint-down(sm) {
    padding: 2rem 2rem 0;
  }
  @include media-breakpoint-up(md) {
    > .row:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
}

.valores .item {
  text-align: center;
  .title {
    color: inherit;
    font-size: heading('heading-5');
    text-decoration: none;
    line-height: 1.1;
    margin-bottom: 0.5rem;
    display: block;
  }
  p {
    font-size: 80%;
  }
}
.valores figure.item .media-header svg {
  height: 92px;
  width: auto;
  fill: currentColor;
  margin-bottom: 1.5rem;
}

@include media-breakpoint-down(sm) {
  h3 {
    font-size: 1.5rem;
  }
}

}
