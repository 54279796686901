.us-cookies-disclaimer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: $primary;
    color: rgb(255, 255, 255);
    z-index: 2147483648;
    padding: 1rem 2rem;
    text-align: center;
    font-size: 12px;
    line-height: 28px;
    border-top: 1px solid white;

    @include media-breakpoint-up(lg) {
        padding: 1rem 5rem;
    }

    .us-cookies-content {
        line-height: 1.8;
    }

    .btn-accept-cookies,
    .btn-deny-cookies,
    .btn-more-info {
        text-decoration: none;
        display: inline-block;
        padding: 0px 15px;
        margin: 0px 0px 0px 10px;
    }

    .btn-accept-cookies {
        background: white;
        color: $primary;
    }

    .btn-deny-cookies {
        background: $primary;
        border: 1px solid white;
        color: white;
    }

    .btn-more-info {
        color: white;
    }
}
