/*
 * ===========================
 *     NAVBAR TRANSPARENTE
 * ===========================
 */
nav {
  padding: 0 !important;
  color: white;
  &.dark {
    padding: $navbar__pading-y 0 !important;
    background-color: $secondary;
  }
  &.transparent {@include media-breakpoint-down(sm) {
      margin: 0 -15px;
      padding: 15px !important;
      position: relative;
      top: -#{$nav-hero__padding-top};
      margin-bottom: -#{$nav-hero__padding-top};
      width: calc(100% + 30px);
      z-index: 1080;
    }
  }
}
nav .logo img {
  max-height: 75px;
  width: auto;
}
nav {
  > .links > ul > li svg.map-arrow {
    position: absolute;
    width: 193px;
    height: 60px; // Linked con .logo img
    right: 0;
    z-index: 40;
    color: white;
    fill: currentColor;
  }
  &.dark > .links > ul > li svg.map-arrow {
    //max-height: 64px;
    top: 0;
    transform: translateY(70%);
    .cls-1,.cls-2 {
        fill: currentColor !important;
    }
  }
}
nav > .links {
  justify-content: flex-end;
  > ul > li { position: relative; }
  @include media-breakpoint-down(lg) {
    > ul > li.dropdown-megamenu { width: 100%; }
  }
  > ul > li > a {
    position: relative;
    color: white;
    text-decoration: none;
    font-weight: weight('medium');
    line-height: 1;
    @include media-breakpoint-down(lg) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    @extend .transition;
    @include media-breakpoint-up(xl) {
      &:not(.icon):not(.dropdown-item) {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
      }
    }
    svg,img {
      line-height: 1;
    }
    &:hover {
      color: lighten($primary, 20%);
    }
  }
  li.active a, li a.active {
    color: $primary;
  }
  @include media-breakpoint-down(sm) {
    .dropdown li.active a, .dropdown li a.active {
      color: white;
      background-color: $primary;
    }
  }
  @include media-breakpoint-up(xl) {
    li.language {
      > a { color: $primary; }
      > a:hover { color: lighten($primary, 20%); }
      border: 1px solid lighten($primary, 20%);
      margin: 0 1.5rem;
    }
  }
  .dropdown-menu:not(.dropdown-megamenu) li a.dropdown-item.active {
    color: white;
    background-color: $primary;
  }
  li.nav-item.dropdown:not(.dropdown-megamenu) > a[aria-expanded="true"]:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid white;
    position: absolute;
    bottom: -5px;
    z-index: 9000;
    left: 4px;
    @include media-breakpoint-up(xl) {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}
nav .dropdown { cursor: pointer;}
nav .dropdown-menu {
    right: 0;
    left: auto;
  a.dropdown-item {
    color: inherit;
    font-size: 80%;
    text-decoration: none;
  }
  li {
      text-align: left !important;
  }
}
nav a[data-toggle="dropdown"] {
  cursor: pointer;
}

/*
 * =================
 *     MEGA MENU
 * =================
 */
.open>.dropdown-container, .show>.dropdown-container,
.dropdown-megamenu > .dropdown-container,
.nav a.caret.nav-link[aria-expanded="false"]:after,
.nav a.caret.nav-link[aria-expanded="true"]:after {
  @extend .transition;
}
@include media-breakpoint-up(xl) {
  .navbar.transparent {
    .nav a.caret.nav-link[aria-expanded="false"]:after,
    .nav a.caret.nav-link[aria-expanded="true"]:after {
      top: calc(32px + .5rem);
    }
  }
  .navbar.dark {
    .nav a.caret.nav-link[aria-expanded="false"]:after,
    .nav a.caret.nav-link[aria-expanded="true"]:after {
      top: 32px;
    }
  }
  .nav a.caret.nav-link[aria-expanded="false"]:after,
  .nav a.caret.nav-link[aria-expanded="true"]:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid white;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .nav a.caret.nav-link[aria-expanded="false"]:after {
    opacity: 0;
    z-index: -1;
  }
  .nav a.caret.nav-link[aria-expanded="true"]:after {
    opacity: 1;
    z-index: 1050;
  }
  .nav > .dropdown-megamenu {
    position: static;
  }
}
@media (max-width: 767px) {
  .navbar-nav .open .dropdown-container {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      border: 0;
      box-shadow: none;
      border-radius: 0;
  }
}
.dropdown-megamenu > .dropdown-container {
  max-width: 100%;
  background-color: white;
  //border: 6px solid #4F5D5B;
  border-radius: 0;
}
@include media-breakpoint-up(xl) {
  .dropdown-megamenu > .dropdown-container {
      cursor: initial;
      position: absolute;
      top: 80px;
      left: 0;
      right: 0;
      max-width: 100%;
      background-color: white;
      //box-shadow: 0px 1px 0px 2px $secondary;
      color: $secondary;
      display: block;
      opacity: 0;
      z-index: -1;
  }
  .open>.dropdown-container, .show>.dropdown-container {
    opacity: 1;
    z-index: 1040;
  }
  .dropdown-megamenu > .dropdown-menu {
    margin: 0;
    padding: 0;
    border: 0;
  }
}
@include media-breakpoint-down(lg) {
  .dropdown-megamenu > .dropdown-container {
    margin-top: 1rem;
    padding: 0;
  }
}
.dropdown-megamenu > .dropdown-container {
  .image {
    padding: 0;
    position: relative;
    overflow: hidden;
    z-index: 99999;
    display: flex;
    a.btn {
      text-transform: inherit;
      z-index: 1;
      position: relative;
      font-weight: weight('medium');
      letter-spacing: 1.1;
    }
  }
  .image.single_btn {
    align-items: flex-end;
    justify-content: center;
  }
  .image.double_btn {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    a.btn:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
  .image img {
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    min-width: 100%;
    min-height: 100%;
  }
  .content {
    padding: 2rem !important;
    .title {
      font-weight: weight('semi-bold');
      font-size: heading('heading-5');
      margin-bottom: 1.5rem;
    }
    ul {
      list-style: none;
      padding: 0;
    }
    ul li { padding-bottom: 0.25rem; position: relative; }
    ul li:not(:last-child) { margin-bottom: .5rem; }
    ul li a {
      display: block;
      font-size: 1rem;
      font-weight: weight('medium');
      padding: 0 !important;
      &.active { color: $primary; }
    }
    ul:not(.image-hover) li:before, ul:not(.image-hover) li:hover:before {
      @extend .transition;
    }
    ul:not(.image-hover) li:before {
      $width: 4px;
      position: absolute;
      left: calc(-#{$width} - #{$width} * 2);
      top: calc(#{heading('heading-5')} * .45);
      content: '';
      height: $width;
      width: $width;
      background-color: $primary;
      border-radius: 100%;
      transform: translateX(-200%);
      opacity: 0;
    }
    ul:not(.image-hover) li:hover:before {
      transform: translateX(0);
      opacity: 1;
    }

    ul.image-hover li a {
      color: $secondary;
      text-decoration: none;
      font-size: calc( #{heading('heading-5')} * .9);
      &.active { color: $primary; }
    }
    ul.image-hover li:after {
      content: '';
      height: 5px;
      width: calc(100% + 2rem);
      border-bottom: 5px solid $primary;
      opacity: 0;
      display: block;
      transform: translateX(100%);
      @extend .transition;
    }
    ul.image-hover li[class^='border-']:after {
      border-bottom-color: inherit;
    }
    ul.image-hover li:hover:after { opacity: 1; transform: translateX(0); }
  }
}


/*
 * =========
 *   FOOTER
 * =========
 */
footer {
    .mini {
      border-top: 1px solid darken($primary, 15%);
      border-bottom: 1px solid darken($primary, 15%);
      background-color: $primary;
      padding: $footer__mini__padding-y $footer__mini__padding-x;
      text-transform: uppercase;
      color: white;
      text-align: center;
      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        &.links {
            li {
                display: inline-block;
                a {
                  color: white;
                  text-decoration: none;
                  position: relative;
                  @extend .transition;
                }
            }
            > li:not(:last-child):after {
              content: '|';
              margin: 0 .75rem;
            }
        }

        &.social-media > li {
            a:hover {
              color: $primary !important;
            }

            .btn {
                width: 35px;
                height: 35px;
                display: flex;
                padding: 0;
                svg {
                    margin: auto;
                }
            }
        }

        &.badgets > li {
            img, svg {
                height: 50px;
                width: auto;
                vertical-align: middle;
            }
        }
      }
    }
    .subfooter {
        h3 {
            color: white;
            text-transform: inherit !important;
        }
        ul.country-list {
            list-style: none;
            padding-left: 0;
            column-count: 2;
            li {
                a {

                    color: white;
                }
            }
        }
        .col-md-4:not(:last-child):after {
            position: absolute;
            content: "";
            width: 1px;
            height: 100%;
            background: $secondary;
            top: 0;
            right: 0;
        }
        a {
            text-decoration: none;
            img {
                width: 62px;
                height: 62px;
                @include media-breakpoint-down(sm) {
                    width: 40px;
                    height: 40px;
                }
            }
            span {
                color: #f29009;
                display: inline-block;
                font-family: Arial,Helvetica,sans-serif;
                font-size: 1.625em;
                font-weight: 700;
                margin-left: 10px;
                vertical-align: middle;
                line-height: 1.2;

                @include media-breakpoint-down(sm) {
                    font-size: 1em;
                }
            }
        }
    }
}

/*
 * ========
 * Social Media Icons
 * ========
 */
 ul.social-media {
     padding: 0;
     margin: 0;
     list-style: none;

     & > li {
         margin-right: .5rem;

         .btn {
             width: 35px;
             height: 35px;
             display: flex;
             padding: 0;
             svg {
                 margin: auto;
             }
         }
     }
 }
 .navbar ul.social-media {
     .btn:not(:hover) {
        background-color: lighten($secondary, 15%) !important;
     }
 }

/*
 * =========
 *  POPOVER
 *  ========
 */
.popover {
    background-color: $secondary;
    border-color: $primary;

    .arrow {
        display: none !important;
    }

    .popover-body,
    .popover-body dt {
        text-align: left;
        color: white;
        a {
            color: white;
            text-decoration: none;
        }
    }
}


/*
 * =========
 *   HERO
 * =========
 */
.hero {
  position: relative;
  padding: $nav-hero__padding-top 0 $hero__padding-y;
  @include media-breakpoint-down(sm) {
    padding: $nav-hero__padding-top 0 calc(#{$hero__padding-y} * .75);
  }

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &:before {
    @extend .overlay;
    background-color: rgba(0,0,0,.55);
  }
  > .container > .content {
    position: relative;
    z-index: 1;
    color: white;
    margin-top: $hero__padding-y;
    @include media-breakpoint-down(sm) {
      margin-top: calc(#{$hero__padding-y} * .75);
    }
    h1,.h1 {
      width: 75%;
      font-weight: weight('semi-bold');
      font-size: 2.7rem;
      text-transform: inherit;
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      }
      line-height: 1.1;
    }
    img {
      height: 64px;
      width: auto;
      min-height: 1px;
      min-width: 1px;
      @include media-breakpoint-down(sm) {
        max-width: 80%;
        height: auto;
      }
    }
  }
}


/*
 * ==================
 *   CARD COMPONENT
 * ==================
 */
.card-component {
  @extend .card-block;
  .image {
    @include media-breakpoint-down(sm) { display: none; }
    margin: -#{$card-component__border} 0;
    &.left {
      padding-left: calc(#{$card-component__border} * 3);
    }
    &.right {
      padding-right: calc(#{$card-component__border} * 3);
    }
  }
  .text {
    margin: auto;
    padding: 1rem 4rem;
    @include media-breakpoint-down(sm) { padding: 2rem; }
    .title {
      font-size: heading('heading-3');
      font-weight: weight('semi-bold');
      line-height: 1.2;
      margin-bottom: 1.5rem;
    }
    p {
      margin: auto;
      font-weight: weight('light');
      font-size: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


/*
 * ==================
 *    CARD CUSTOM
 * ==================
 */
.card.custom {
  /* TODO: MODIFICAR EL BORDE DE ABAJO!! */
  box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 0;
}

#blog .card.custom {
  padding-bottom: 3px;
  .card-body.blog {
    padding: $card-custom__blog__padding-y $card-custom__blog__padding-x;
  }
  .date {
    font-style: italic;
    font-size: 90%;
    opacity: .7;
    margin-bottom: .5rem;
  }
  a.title {
    margin-bottom: 1rem;
    line-height: 1.1;
    color: $primary;
    font-size: 140%;
    font-weight: weight('semi-bold');
    text-decoration: none;
    display: block;
  }
  p { font-size: 90%; }
  a.read-more {
    letter-spacing: 1;
    font-weight: weight('semi-bold');
  }
}
#blog ul.comments {
  list-style: none;
  padding: 0;
  margin: 0;
  li:first-child { border-top: 1px solid $gray-300; }
  > li {
    border-bottom: 1px solid $gray-300;
    padding: 1.5rem 0;
  }
}
#blog ul.comments > li > ul {
  list-style: none;
  padding: 0;
  margin: 3rem 0 0;
  > li:not(:last-child) {
    border-bottom: 1px solid $gray-300;
  }
  > li {
    padding: 1.5rem 2rem;
    position: relative;
  }
  > li:before {
    content: '';
    position: absolute;
    left: calc(1.5rem / 2); // padding del > li
    top: 0;
    bottom: 0;
    margin: auto;
    height: calc(100% - 1.5rem * 2);
    width: 4px;
    background-color: $primary;
  }
}
#blog ul.comments li .author {
  font-size: heading('heading-5');
  font-weight: weight('semi-bold');
  position: relative;
  margin-bottom: 1rem;
  .date {
    font-size: 80%;
    font-weight: weight('light');
    padding-left: .5rem;
  }
  .date::before {
    content: '';
    width: 3px;
    height: 3px;
    background-color: #dee2e6;
    border-radius: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: translateX(-0.5rem);
  }
}
#blog ul.comments li p:last-of-type { margin-bottom: 0; }
.card.custom .partners {
  padding: 4rem;
  @include media-breakpoint-down(sm) {
    padding: 2rem;
    .row figure:not(:last-of-type) { margin-bottom: 2rem; }
  }
}
.card.custom .partners .item {
  .company {
    margin: 1.5rem 0 .5rem;
    font-size: 90%;
    text-transform: uppercase;
    font-weight: weight('semi-bold');
  }
  .title {
    color: inherit;
    font-size: heading('heading-5');
    text-decoration: none;
    line-height: 1.1;
    margin-bottom: 0.5rem;
    display: block;
  }
  p {
    font-size: 80%;
  }
}

/*
 * ========
 *   CTA
 * ========
 */
.container-fluid.cta {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  color: white;
  padding: $cta__padding-y $cta__padding-x;
  .content {
    .heading {
      font-size: heading('heading-2');
      font-weight: weight('semi-bold');
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
        margin-bottom: 1.5rem;
        line-height: 1;
      }
    }
    p {
      font-size: 1.1rem;
      &:not(:last-child) { margin-bottom: 0; }
    }
    .btn {
      margin-top: 1rem;
      padding: 0.5rem 2.5rem !important;
      font-size: 80%;
    }
  }
}
#cta-gourmetpay-usuario .col-12 {
    position: relative;
    > div {
        position: absolute;
        bottom: 6%;
        left: 21%;
        .btn-dwn {
            display: inline;
            img {
                width: 14%;
                max-height: none;
            }
        }
    }
}

#cta-gourmetpay-restaurante .col-12 {
    position: relative;
    > div {
        position: absolute;
        bottom: 3%;
        right: 0;
        text-align: right;
        margin-right: 8%;
        .btn-dwn {
            display: inline;
            img {
                width: 33%;
                max-height: none;
            }
        }
    }
}

/*
 * ================
 *    UNDERLINE
 * ================
 */
.underline {
  position: relative;
  padding-bottom: 1.5rem;
}
.underline:after {
  content: '';
  position: absolute;
  bottom: 0;
  height: $underline__height;
  background-color: $primary;
  width: 10vw;
  @include media-breakpoint-down(md) {
    width: 30vw;
  }
}
.underline:after { background-color: $primary; }
@each $name, $value in $colors {
  .underline-#{$name}:after {
    background-color: $value;
  }
}
.underline.left:after { left: 0; }
@include media-breakpoint-up(md) {
  .underline.right:after { right: 0; }
}
.underline.center:after {
  left: 0;
  right: 0;
  margin: 0 auto;
}


/*
 * ========
 *   FAQ
 * ========
 */
.faq-container {
  .category {
    margin: 1rem 0;
    padding: 1rem 0;
    border-top: 1px solid rgba(0,0,0,.2);
    border-bottom: 1px solid rgba(0,0,0,.2);
    &:not(:last-of-type) {
      margin-bottom: 5rem;
    }
  }
  .item:not(:last-of-type) { margin-bottom: 2rem; }
  .item h4 {
    cursor: pointer;
    color: $primary;
    font-weight: weight('semi-bold');
    margin-bottom: 1rem;
    font-size: 1.25rem;
    > svg {
      font-size: 60%;
      margin-right: .5rem;
    }
  }
  .item h4:before {
    @extend .font-awesome-solid;
    content: "\f068";
  }
  .item h4.collapsed:before {
    @extend .font-awesome-solid;
    content: "\f067";
  }
}

/*
 * ================
 *   CALCULADORA
 * ================
 */
#calculadora {
  .card-body {
    padding: 0 !important;
  }
  svg, path {
      fill: currentColor;
  }
  img, svg {
    max-height: 64px;
    width: auto;
  }
  label {
    font-weight: weight('semi-bold');
    padding: 0;
    font-size: 80%;
  }
  input[readonly],input.readonly {
    text-align: center;
    border-width: 3px;
    background-color: white;
    line-height: 1;
    padding: 0.2rem;
    font-style: normal;
  }
  .btn.btn-add, .btn.btn-subtract {
    color: $primary !important;
    background-color: #4F5D5B !important;
    border-color: #4F5D5B !important;
    font-weight: weight('bold');
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn.btn-add.text-educainfantil, .btn.btn-subtract.text-educainfantil {
      color: $educainfantil !important;
  }
  .btn.btn-add.text-transport-ticket, .btn.btn-subtract.text-transport-ticket {
      color: $transport-ticket !important;
  }
  .card-body.row > div[class^="col"] .row {
    align-items: center;

  }

  input[type=submit] {
    padding: .5rem 1rem !important;
  }
  
  .hs-error-msgs {
    display: none !important;
  }

  .hs_email .hs-error-msgs {
    display: block !important;
  }

  input[type=range] {
    -webkit-appearance: none;
    border: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: $secondary;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background: $primary;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5.2px;
  }
  input[type=range].educainfantil::-webkit-slider-thumb {
      background: $educainfantil;
  }
  input[type=range].transport-ticket::-webkit-slider-thumb {
      background: $transport-ticket;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: $secondary;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: $secondary;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background: $primary;
    cursor: pointer;
  }
  input[type=range].educainfantil::-moz-range-thumb {
      background: $educainfantil;
  }
  input[type=range].transport-ticket::-moz-range-thumb {
      background: $transport-ticket;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: $secondary;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type=range]::-ms-fill-upper {
    background: $secondary;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background: $primary;
    cursor: pointer;
    height: 5px;
  }
  input[type=range].educainfantil::-ms-thumb {
      background: $educainfantil;
  }
  input[type=range].transport-ticket::-ms-thumb {
      background: $transport-ticket;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: $secondary;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: $secondary;
  }
}

#calculadora-ahorro {
  .hbspt-form .legal-consent-container {
    font-size: .6rem;
  }
  .hbspt-form .hs_email > label {
    padding: 0;
  }
  .hbspt-form ul.inputs-list li > label {
    padding: 0;
  }
  .btn.disabled {
    transition: .2s opacity ease-in-out !important;
    &:hover {
      opacity: .9 !important;
    }
  }
  .solution {
    padding: 2rem 1.5rem;
    width: 100%;
    margin: 0;
    position: relative;
    transition: all .2s ease-in-out;
    z-index: 3;
  }
  .solution:not(:first-of-type) {
    box-shadow: 0px -8px 12px -13px rgba(0, 0, 0, 0.56);
  }
  .solution.disabled {
    z-index: 3;
    transition: all .2s ease-in-out;
    &:before, &:after {
      position: absolute;
      left:0;top:0;
      width: 100%;
      height: 100%;
      display: flex;
      transition: all .2s ease-in-out;
    }
    &:hover:before {
      color: $primary;
    }
    &#cheque_gourmet:before {
      content: "Haz clic para activar Up Cheque Gourmet";
    }
    &#transporte:before {
      content: "Haz clic para activar Up Cheque Transporte";
    }
    &#educainfantil:before {
      content: "Haz clic para activar Up Educainfantil";
    }
    &:before {
      margin: auto;
      text-align: center;
      z-index: 10;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 600;
      cursor: pointer;
      transition: .2s all ease-in-out;
    }
    &:after {
      background-color: rgba(255,255,255,.9);
      content: '';
      z-index: 1;
    }
  }
  .btn.btn-add,.btn.btn-substract {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
  }
  svg, path {
    fill: currentColor;
  }
  img, svg {
    max-height: 64px;
    width: auto;
  }
  svg.icon, path.icon {
    height: 64px;
  }
  label {
    font-weight: weight('semi-bold');
  }
  input[readonly],input.readonly,input[type="text"] {
    text-align: center;
    border-width: 3px;
    line-height: 1;
    padding: 0.2rem;
    font-style: normal;
  }
  input[readonly],input[disabled] {
    background-color: #fff4e6;
  }
  input[type=range] {
    -webkit-appearance: none;
    border: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: $secondary;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background: $primary;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5.2px;
  }
  input[type=range].educainfantil::-webkit-slider-thumb {
      background: $educainfantil;
  }
  input[type=range].transport-ticket::-webkit-slider-thumb {
      background: $transport-ticket;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: $secondary;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: $secondary;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background: $primary;
    cursor: pointer;
  }
  input[type=range].educainfantil::-moz-range-thumb {
      background: $educainfantil;
  }
  input[type=range].transport-ticket::-moz-range-thumb {
      background: $transport-ticket;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: $secondary;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type=range]::-ms-fill-upper {
    background: $secondary;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background: $primary;
    cursor: pointer;
    height: 5px;
  }
  input[type=range].educainfantil::-ms-thumb {
      background: $educainfantil;
  }
  input[type=range].transport-ticket::-ms-thumb {
      background: $transport-ticket;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: $secondary;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: $secondary;
  }
}


/*
 * ================
 *   CAROUSEL
 * ================
 */
.carousel {
    border: 5px solid #f59000;
    padding: 2rem 4rem;
    border-radius: 34px;

    .left-quote {
        position: absolute;
        left: 0;
        top: -39px;
        display: flex;
        align-items: center;
        background-color: #fff;
        font-size: 9rem;
        color: #f59000;
        height: 73px;
        width: 88px;
        padding-left: 20px;
        padding-top: 6rem;
        margin-left: -30px;
    }

    .right-quote {
        position: absolute;
        right: -43px;
        bottom: -39px;
        display: flex;
        align-items: center;
        background-color: #fff;
        font-size: 9rem;
        color: #f59000;
        height: 73px;
        width: 88px;
        padding-left: 20px;
        padding-top: 6rem;
        margin-left: -30px;
    }

    .carousel-control {
      svg {
          height: 2rem !important;
          width: auto !important;
      }

      &.carousel-control-prev svg {
          margin-right: 1rem;
      }

      &.carousel-control-next svg {
          margin-left: 1rem;
      }

      @include media-breakpoint-up(sm) {
          &.carousel-control-prev svg {
              margin-right: 2rem;
          }

          &.carousel-control-next svg {
              margin-left: 2rem;
          }
      }
  }

}
.carousel .item .img-box img {
    height: auto;
    width: 100%;
}
.h-30 {
  height: 30%;

  & > img{
    height: 100% !important;
    max-width: 100% !important;
    object-fit: cover !important;
    object-position: center !important;
  }
}

/* Cookies banner */
#tarteaucitronRoot {
    .tarteaucitronAlertBigBottom {
        background: #4f5b59 !important;

        span {
            font-size: 12px !important;
        }
    }
    #tarteaucitronPersonalize {
        background-color: #f59000 !important;
        border-color: #f59000;
        border-radius: 50px;
    }
    #tarteaucitronCloseAlert {
        border-radius: 50px;
    }
    #tarteaucitronPercentage {
        background: #f59000 !important;
    }
    #tarteaucitronIcon {
      bottom: 83px;
    }
}

#hubspot-messages-iframe-container.widget-align-right {
    z-index: 2147483610 !important;
}


.sticky-banner {
  position: sticky;
  top: -235px;
}

.sticky-block {
  position: sticky;
  top: 40px;
}

.index-menu {
  // background-color: $primary-light;
  // border: 2px solid $primary;
  padding: 1rem;

  & > p {
    position: relative;
    margin-bottom: 1.5rem;

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: -8px;
      left: 0;
      background-color: $primary;
    }
  }
  ol {
    list-style: none;
    padding-left: .5rem;

    li {

      padding-bottom: 1rem;

      a {
        color: $secondary;
        position: relative;
        font-weight: 500;
        &:hover {
          color: $primary;
        }
        &:after {
          content: '';
        position: absolute;
        bottom: 0;
        left: calc(30px + .5rem);
        width: calc(100% - 30px - .5rem);
        height: 1px;
        background-color: $secondary-light;
        }
        svg {
          width: 30px;
          height: auto;
          margin-right: .5rem;
        }
      }
    }
  }
}