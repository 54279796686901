/* All HubSpot Forms
   ========================================================================== */

/* Eliminar label de campo obligatorio de formularios */
.hbspt-no-label {
 label[id^=label]:not([class*="hs-form-booleancheckbox-display"]) { display: none; }
}

/* Group */
.hs-form-field {
  margin-bottom: 1rem;
}
.hs-form .legal-consent-container > .hs-richtext > p {
    font-size: 60%;
}

/* Form Field (selector for form field wrapper) */
.hs-form .hs-form-field {}

/* Descriptions (targets class applied to Help Text divs) */
.hs-form .hs-field-desc {}

/* Labels (selects field labels and error messages) */
.hs-form label { }
#calculadora .hs-form ul.inputs-list, .hs-form [class*="hs_LEGAL_CONSENT"] {
  margin-bottom: 0;
}
#calculadora .hs-form .legal-consent-container {
  label[for*="LEGAL_CONSENT"] {
    font-weight: 100 !important;
    font-size: 0.6rem;
    margin-bottom: 0;
    padding: 0 !important;
  }
}
.hs-form .hs-form-field > label {}

/* Inputs (selectors for all inputs)  */
.hs-form input[type="text"],
.hs-form input[type="password"],
.hs-form input[type="datetime"],
.hs-form input[type="datetime-local"],
.hs-form input[type="date"],
.hs-form input[type="month"],
.hs-form input[type="time"],
.hs-form input[type="week"],
.hs-form input[type="number"],
.hs-form input[type="email"],
.hs-form input[type="url"],
.hs-form input[type="search"],
.hs-form input[type="tel"],
.hs-form input[type="color"],
.hs-form input[type="file"],
.hs-form textarea,
.hs-form select {
  @extend .form-control;
}
.hs-form select:not([size]):not([multiple]) {
    height: calc(2.25rem + 5px);
}

/* Inputs in focus (selectors for all inputs when clicked)  */
.hs-form input[type="text"]:focus,
.hs-form input[type="password"]:focus,
.hs-form input[type="datetime"]:focus,
.hs-form input[type="datetime-local"]:focus,
.hs-form input[type="date"]:focus,
.hs-form input[type="month"]:focus,
.hs-form input[type="time"]:focus,
.hs-form input[type="week"]:focus,
.hs-form input[type="number"]:focus,
.hs-form input[type="email"]:focus,
.hs-form input[type="url"]:focus,
.hs-form input[type="search"]:focus,
.hs-form input[type="tel"]:focus,
.hs-form input[type="color"]:focus,
.hs-form input[type="file"]:focus,
.hs-form textarea:focus,
.hs-form select:focus {}

/* Multi-line inputs (selectors to target multi-line fields */
.hs-form textarea {}
.hs-form textarea:focus {}

/* Dropdowns (selectors for dropdowns) */
.hs-form select {}
.hs-form select:focus {}

/* Multi-select (selectors for multi-select fields) */
.hs-form form.hs-form .hs-form-field ul.inputs-list, .hs-form ul.inputs-list {
  @extend .list-unstyled;
}

.hs-form form.hs-form .hs-form-field ul.inputs-list li input {}
.hs-form input[type="radio"] {
  & + span {
    padding-left: .5rem;
  }

}
.hs-form input[type="checkbox"] {
    margin-right: 5px;
}

/* Required (selectors for fields, when they do not pass validation) */
.hs-form input:focus:required:invalid,
.hs-form textarea:focus:required:invalid,
.hs-form select:focus:required:invalid {}

.hs-form input:focus:required:invalid:focus,
.hs-form textarea:focus:required:invalid:focus,
.hs-form select:focus:required:invalid:focus {}

/* Error message (selector for validation messages) */
.hs-form .hs-error-msgs label{}

/* Placeholder Text (styles the placeholder attribute text) */
::-webkit-input-placeholder { /* Webkit Browsers */}
:-moz-placeholder { /* Firefox 18- */}
::-moz-placeholder { /* Firefox 19+ */}
:-ms-input-placeholder { /* IE10 */}

/* Multi Column Form (selectors for fieldsets and field wrappers)
   ========================================================================== */

.hs-form fieldset.form-columns-1 {}
.hs-form fieldset.form-columns-1 .hs-form-field {}
.hs-form fieldset.form-columns-1 .hs-form-field input[type="text"],
.hs-form fieldset.form-columns-1 .hs-form-field input[type="email"],
.hs-form fieldset.form-columns-1 .hs-form-field select {
    width: 100%;
}

.hs-form fieldset.form-columns-2 {}
.hs-form fieldset.form-columns-2 .hs-form-field {}

.hs-form fieldset.form-columns-3 {}
.hs-form fieldset.form-columns-3 .hs-form-field {}

/* Submit buttons (selectors for all non-CTA buttons)
   ========================================================================== */

body .hs-button.primary,
body .hs-button.primary input[type="submit"],
body .hs-button.primary input[type="button"] {
  @extend .btn;
  @extend .btn-sm;
  @extend .btn-primary;
  padding: 0.5rem 2rem !important;
}
body .hs-button.btn-educainfantil {
    @extend .btn-educainfantil;
}
body .hs-button.btn-transport-ticket {
    @extend .btn-transport-ticket;
}

body .hs-button.primary:hover,
body .hs-button.primary input[type="submit"]:hover,
body .hs-button.primary input[type="button"]:hover {}

body .hs-button.primary:focus,
body .hs-button.primary input[type="submit"]:focus,
body .hs-button.primary input[type="button"]:focus {}
