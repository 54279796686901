@mixin transitioning {
  transition: all .5s cubic-bezier(.33,0,.2,1);
}
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin dot-filter {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:
		linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
		linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
		$dot-color;
	background-size: $dot-space $dot-space;
}

@mixin regular-filter($color: rgba(0,0,0,.4)) {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// antialiasing mode font rendering
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin filter($filter-type,$filter-amount) {
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}

/* Custom font weights */
@function weight($key) {
  @if map-has-key($weights, $key) {
    @return map-get($weights, $key);
  }

  @warn "Unknown `#{$key}` in $weights.";
  @return null;
}
@each $name, $value in $weights {
  .font-weight-#{$name} {
    font-weight: $value !important;
  }
}

/* Custom fonts */
@function font($key) {
  @if map-has-key($fonts, $key) {
    @return map-get($fonts, $key);
  }

  @warn "Unknown `#{$key}` in $fonts.";
  @return null;
}
@each $name, $value in $fonts {
  .font-#{$name} {
    font-family: $value !important;
  }
}

/* Custom heading sizes */
@function heading($key) {
  @if map-has-key($heading-sizes, $key) {
    @return map-get($heading-sizes, $key);
  }

  @warn "Unknown `#{$key}` in $heading-sizes.";
  @return null;
}
@each $name, $value in $heading-sizes {
  .#{$name} {
    font-size: $value;
  }
}
