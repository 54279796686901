#afiliacion {

h3 {
  letter-spacing: 1;
  font-weight: weight('semi-bold');
}

.ventajas {
  @include media-breakpoint-down(sm) {
    .flex-nowrap { flex-wrap: wrap !important; }
  }
  .item {
    text-align: center;
    @include media-breakpoint-down(sm) {
      &:not(:nth-last-of-type(-n+2)) { margin-bottom: 1.5rem; }
    }
  }
  .item svg, .item img, .item path {
    color: inherit;
    fill: currentColor;
    max-height: 64px;
    width: auto;
    margin-bottom: 1rem;
  }
  h5 {
    font-size: 90%;
  }
}

}
